import classNames from 'classnames';
import {ComponentChildren} from 'preact';

/**
 * This component displays a button with a loading indicator. This is
 * useful for buttons that perform an asynchronous action, such as
 * submitting a form. If the `isLoading` prop is true, the loader will
 * be displayed. Otherwise, the button will be displayed.
 */
export default function ButtonLoadingIndicator({
  button,
  fullWidth = false,
  loader,
  isLoading = false,
  className = '',
}: LoaderButtonProps) {
  return (
    <div
      className={classNames('relative h-max', {
        'w-full': fullWidth,
        'w-max': !fullWidth,
        [className]: true,
      })}
    >
      <div
        className={classNames(
          'absolute inset-0 flex items-center justify-center',
          {
            hidden: !isLoading,
          }
        )}
      >
        {loader}
      </div>
      <div
        className={classNames('w-full', {
          'opacity-0': isLoading,
        })}
      >
        {button}
      </div>
    </div>
  );
}

export type LoaderButtonProps = {
  button: ComponentChildren;
  className?: string;
  fullWidth?: boolean;
  isLoading?: boolean;
  loader: ComponentChildren;
};
